import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/css/index.css'
import wx from 'weixin-js-sdk';
Vue.config.productionTip = false;
Vue.prototype.$wx = wx

Vue.use(Antd);

router.beforeEach((to, form, next)=>{
	document.title = to.meta.title || '';
	next();
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
